import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, LinkBox, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				OakBridge Legal Solutions
			</title>
			<meta name={"description"} content={"Gerechtigkeit stärken, Seelenfrieden fördern"} />
			<meta property={"og:title"} content={"OakBridge Legal Solutions"} />
			<meta property={"og:description"} content={"Gerechtigkeit stärken, Seelenfrieden fördern"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link4" />
			<Override slot="link3" />
			<Override slot="text">
				OakBridge Legal Solutions
			</Override>
			<Override slot="text1" />
			<Override slot="link5" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link1" />
		</Components.Header2>
		<Components.Hero>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="box3" />
			<Override slot="box1" />
			<Override slot="box" />
			<Override slot="icon" />
			<Override slot="icon1" />
			<Override slot="text3" />
			<Override slot="text2" />
		</Components.Hero>
		<Section
			padding="80px 0 90px 0"
			quarkly-title="Headline-4"
			border-width="0 0 .1px 0"
			border-style="solid"
			border-color="--color-darkL2"
		>
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="--headline2"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Über OakBridge Legal Solutions
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="--dark" sm-margin="0px 0px 25px 0px">
						OakBridge Legal Solutions basiert auf den Säulen Integrität, Sorgfalt und beispiellosem professionellen Scharfsinn und ist ein Leuchtturm der juristischen Kompetenz. Unser Team besteht aus erfahrenen Anwälten und Rechtsexperten, die auf eine Vielzahl von Tätigkeitsbereichen spezialisiert sind. Jeder Fall wird mit akribischer Liebe zum Detail und einem strategischen Ansatz behandelt, der darauf ausgelegt ist, Erfolg und Kundenzufriedenheit zu maximieren.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-9">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 70px 0px"
				lg-flex-direction="column"
				text-align="center"
				flex-direction="column"
				max-width="100%"
			>
				<Text
					margin="0px 0px 25px 0px"
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
				>
					Warum Sie sich für uns entscheiden sollten?
				</Text>
				<Text margin="0px 0px 20px 0px" color="--darkL1" font="--base" width="70%">
					Ihre Privatsphäre und Ihr Vertrauen haben oberste Priorität. Wir halten die strengsten Standards der Vertraulichkeit und ethischen Praxis ein.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/2-2.jpg?v=2024-07-12T08:50:46.769Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Unübertroffene Professionalität
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Unser Rechtsteam bringt jahrzehntelange Erfahrung mit und gewährleistet so eine sachkundige und professionelle Vertretung jedes Mandanten.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/2-1.jpg?v=2024-07-12T08:50:46.768Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Kundenorientierter Ansatz
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ihre Bedürfnisse und Ziele stehen bei uns an erster Stelle und wir bieten Ihnen während Ihres gesamten Rechtswegs personalisierte Lösungen und transparente Kommunikation.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/2-3.jpg?v=2024-07-12T08:50:46.736Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ergebnisorientierte Strategien
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Mit innovativen Strategien und gründlicher Planung streben wir danach, die bestmöglichen Ergebnisse für unsere Mandanten zu erzielen.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Unsere wichtigsten Rechtsdienstleistungen
				</Text>
				<Button type="link" text-decoration-line="initial" text-align="center" href="/services">
					Mehr anzeigen
				</Button>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 10px 0px" color="#F7FBFF" font="--headline3">
					Gesellschaftsrecht
				</Text>
				<Text margin="0px 0px 10px 0px" color="#F7FBFF" font="--headline3">
					Familienrecht
				</Text>
				<Text margin="0px 0px 10px 0px" color="#F7FBFF" font="--headline3">
					Strafverteidigung
				</Text>
				<Text margin="0px 0px 10px 0px" color="#F7FBFF" font="--headline3">
					Geistiges Eigentum
				</Text>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				Unsere Expertise
			</Override>
			<Override slot="text1">
				Bei OakBridge sind wir auf ein breites Spektrum von Rechtsgebieten spezialisiert, darunter Gesellschaftsrecht, Familienrecht, Strafverteidigung und geistige Eigentumsrechte. Unser ganzheitlicher Ansatz stellt sicher, dass jeder Mandant eine umfassende Beratung erhält, die auf seine individuellen rechtlichen Bedürfnisse zugeschnitten ist.
			</Override>
		</Components.Cta>
		<Components.Header2>
			<Override slot="text" />
		</Components.Header2>
		<Components.Footer>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});